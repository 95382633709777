<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`Reports - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Ensemble Roster" active/>
    </template>

    <template #dropdown-options>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{state}">
			<span class="d-none d-print-block p-50 font-weight-bold">{{ selectedEnsemble }}</span>
			<table-layout ref="table-layout"
										:loading="table.loading"
										:fields="table.fields"
										:items="table.items"
										:sorting="table.sorting"
										:filters="table.filters" :filters-options="{ visible: true, collapsed: false }"
                    :export-exclude-fields="[
                        'id',
                        'selectionEnsembleId',
                        'application.id',
                        'application.student.id',
                        'application.student.school.id',
                        'application.student.school.zone.id',
                        'application.instrument.id',
                        'application.teacher.id',
                        'application.teacher.username',
                        'iop', 'hasDetails'
                    ]"
										@mounted="table = $event"
										@updated="table = $event">

				<template #overlay>
					<overlay-loading :items="[
            { state: table.loading, desc: 'Loading Selections', loaded: table.loaded},
            { state: state.loading, desc: 'Rendering Template'},
          ]" />
        </template>

				<template #filters>
					<b-row>
						<b-col>
							<b-form-group label="Ensemble" label-for="ensemble-input">
								<template #label>
									Ensemble (<span class="text-danger">required</span>)
								</template>
								<v-select id="ensemble-input"
													v-model="table.filters.ensemble.value"
													:options="options.ensembles.items"
													:loading="options.ensembles.loading"
													:reduce="ensemble => ensemble.id"
													label="name"
													:select-on-tab="true"
													:clearable="false"
													:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
													class="w-100 d-print-none"
													@input="getSelections"/>
							</b-form-group>
						</b-col>
            <b-col>
              <b-form-group label="Zone" label-for="zone-input">
                <v-select id="zone-input"
                          v-model="table.filters.zone.value"
                          :options="options.zones.items"
                          :loading="options.zones.loading"
                          :reduce="zone => zone.id"
                          label="name"
                          :disabled="!table.filters.ensemble.value"
                          :select-on-tab="true"
                          :clearable="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100 d-print-none"/>
              </b-form-group>
            </b-col>
					</b-row>
				</template>

        <template #cell(application.student.address)="{data}">
          <span v-if="data.item.application.student.address">
            <div>
              <span>{{ data.item.application.student.address.line1 }}</span>
              <span v-if="data.item.application.student.address.line2">, {{ data.item.application.student.address.line2 }}</span>
            </div>
            <div>
              {{ data.item.application.student.address.city }}, {{ data.item.application.student.address.state }} {{ data.item.application.student.address.zip }}
            </div>
          </span>
        </template>

        <!-- Instrument -->
        <template #cell(application.instrument.name)="{data}">
          <span v-if="hasValue(data, 'item.application.instrument')">
            {{ data.item.application.instrument.name }}
          </span>
          <span v-else class="text-danger">No Instrument</span>
        </template>

        <!-- Instrument Order -->
        <template #cell(application.instrument.order)="{data}">
          <span v-if="hasValue(data, 'item.application.instrument')">
            {{ data.item.application.instrument.order }}
          </span>
          <span v-else class="text-danger">No Instrument</span>
        </template>

        <!-- Part -->

        <!-- Student Name Last -->
        <template #cell(application.student.name.last)="{data}">
          <span v-if="hasValue(data, 'item.application.student.name')">
            {{ data.item.application.student.name.last }}
          </span>
          <span v-else class="text-danger">No Student</span>
        </template>

        <!-- Student Name First -->
        <template #cell(application.student.name.first)="{data}">
          <span v-if="hasValue(data, 'item.application.student.name')">
            {{ data.item.application.student.name.first }}
          </span>
          <span v-else class="text-danger">No Student</span>
        </template>

        <!-- School -->
        <template #cell(application.student.school.name.legal)="{data}">
          <span v-if="hasValue(data, 'item.application.student.school.name')">
            {{ data.item.application.student.school.name.legal }}
          </span>
          <span v-else class="text-danger">No School</span>
        </template>

        <!-- Teacher Name -->
        <template #cell(application.teacher.name)="{data}">
          <span v-if="hasValue(data, 'item.application.teacher.name')">
            {{ data.item.application.teacher.name.first }} {{ data.item.application.teacher.name.last }}
          </span>
          <span v-else class="text-danger">No Teacher</span>
        </template>

        <!-- Teacher Email -->
<!--        <template #cell(application.teacher.email)="{data}">
          <span v-if="hasValue(data, 'item.application.teacher.email')">
            {{ data.item.application.teacher.email }}
          </span>
          <span v-else class="text-danger">No Teacher</span>
        </template>-->

        <!-- Zone -->
        <template #cell(application.student.school.zone.name)="{data}">
          <span v-if="hasValue(data, 'item.application.student.school.zone.name')">
            {{ data.item.application.student.school.zone.name }}
          </span>
          <span v-else class="text-danger">No Zone</span>
        </template>



        <!-- Accepted -->
				<template #cell(accepted)="{data}">
					<b-badge :variant="getAcceptedVariant(data.item.accepted)">
						{{ getAcceptedText(data.item.accepted) }}
					</b-badge>
				</template>

      </table-layout>
    </template>

  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {getUser, listEnsembles, listSelections} from './queries/ensemble-roster';
import vSelect from 'vue-select';
import _ from 'lodash';
import Fuse from 'fuse.js';
import TableLayout from '@/components/TableLayout.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import acceptanceMixin from '@/mixins/acceptance.mixin';
import {adminListUsersInGroup} from '@/scripts/aws';
import lodashMixin from '@/mixins/lodash.mixin';
import settingsMixin from '@/mixins/settings.mixin';

export default {
  name: 'EnsembleRoster',
  components: {
    OverlayLoading,
    TableLayout,
    PageLayout,
    vSelect
  },
  mixins: [ acceptanceMixin, lodashMixin, settingsMixin ],
  data() {
    return {
      user: this.$store.state.user,
      table: {
        fields: [
          {
            key: 'application.instrument.name',
            label: 'Instrument',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.instrument.order',
            label: 'Instrument Order',
            sortable: false,
            filterable: false,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'part',
            label: 'Part',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.name.last',
            label: 'Last Name',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.name.first',
            label: 'First Name',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.grade',
            label: 'Grade',
            sortable: false,
            filterable: false,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.address',
            label: 'Address',
            sortable: false,
            filterable: false,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.phone.number',
            label: 'Phone',
            sortable: false,
            filterable: true,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.email.address',
            label: 'Email',
            sortable: false,
            filterable: false,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.school.name.legal',
            label: 'School',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.school.district.name.legal',
            label: 'District',
            sortable: false,
            filterable: true,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'application.teacher.name',
            label: 'Teacher Name',
            sortable: false,
            filterable: false,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'application.teacher.email',
            label: 'Teacher Email',
            sortable: false,
            filterable: false,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.school.zone.name',
            label: 'Zone',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
              key: 'accepted',
              label: 'Accepted',
              sortable: false,
              filterable: false,
              visible: true,
              tdClass: 'align-middle'
          },
          {
            key: 'ranking.state',
            label: 'State Rank',
            sortable: false,
            filterable: false,
            visible: false,
            tdClass: 'align-middle'
          },
        ],
				sorting: {
					by: 'id',
					comparator: (a, b) => this.compareByInstrumentOrderAndPart(a, b),
					comparatorKey: 'iop',
					comparators: [
						{ key: 'iop', text: 'Instrument Order & Part', comparator: (a, b) => this.compareByInstrumentOrderAndPart(a, b) },
					]
				},
        search: {
          type: '\'',
          value: '',
          options: {
            types: [
              { text: 'Fuzzy', value: '' },
              { text: 'Exact', value: '=' },
              { text: 'Includes', value: '\'' },
              { text: 'Starts With', value: '^' },
            ]
          },
        },
        filters: {
          ensemble: { key: 'selectionEnsembleId', value: null },
          zone: { key: 'application.student.school.zone.id', value: null },
        },
        items: [],
        loading: false
      },
      users: [],
      usersMap: {},
      options: {
        ensembles: {
          items: [],
          loading: true
        },
        zones: {
          items: [],
          loading: false
        }
      },
    }
  },
  computed: {
    selectedEnsemble() {
      return this.options.ensembles.items.find(item => item.id === this.table.filters.ensemble.value)?.name || null
    }
  },
  async mounted() {
    await this.getCurrentUser()
    await this.listEnsembles()
    this.$refs.layout.state.loading = false
    if(this.$route.query.ensemble) {
      this.table.filters.ensemble.value = this.$route.query.ensemble
      await this.getSelections(this.$route.query.ensemble)
    }
  },
  methods: {
    async getCurrentUser() {
      /** Get Current User from Store **/
      const cognitoUser = await Auth.currentAuthenticatedUser()

      /** Get User from AppSync **/
      const response = await API.graphql(graphqlOperation(getUser, { id: cognitoUser.attributes['custom:user_id'] }));
      this.user = response.data.getUser
      this.user.groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups']
    },
    async listEnsembles() {
      const response = await API.graphql(graphqlOperation(listEnsembles));
      const items = response.data.listEnsembles.items.sort((a, b) => a.name.localeCompare(b.name));

      if(this.user.groups.includes('NYSSMA_Chair')) {
        this.options.ensembles.items = items.filter(item => this.user.ensembleIds.includes(item.id))
      }
      else {
        this.options.ensembles.items = items
      }
      this.options.ensembles.loading = false
    },
    async getSelections(ensembleId) {
      if(!this.table.items.some(item => item.selectionEnsembleId === ensembleId)) {
        if(this.$route.query?.ensemble !== ensembleId) {
          await this.$router.push({query: {ensemble: ensembleId}})
        }
        this.table.loading = true
        await this.listSelections(ensembleId)
      }
    },
    async listSelections(ensembleId, nextToken, pagedSelections) {
      this.options.zones.loading = true

      const selections = pagedSelections || []
      const input = {
        filter: {
          selectionEnsembleId: { eq: ensembleId },
          accepted: { ne: false },
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start, this.settingsStore.app.current.year.end
            ]
          },
        },
        limit: 500,
        nextToken: nextToken
      }

      const response = await API.graphql(graphqlOperation(listSelections, input));
      selections.push(...response.data.listSelections.items);

      if(response.data.listSelections.nextToken) {
        await this.listSelections(ensembleId, response.data.listSelections.nextToken, selections)
      }
      else {
        if(this.table.fields.find(field => field.key === 'application.teacher.email')?.visible) {
          if(Object.keys(this.usersMap).length === 0) {
            const users = await adminListUsersInGroup('Teacher')

            // create a map for quick lookup and store it
            this.usersMap = users.reduce((map, user) => {
              const userId = user.Attributes.find(attr => attr.Name === 'custom:user_id').Value;
              const emailAttr = user.Attributes.find(attr => attr.Name === 'email');
              if(emailAttr) {
                map[userId] = emailAttr.Value;
              }
              return map;
            }, {});
          }

          // merge email addresses into selections
          selections.forEach((selection) => {
            const teacherId = selection?.application?.teacher?.id;
            if(teacherId && Object.prototype.hasOwnProperty.call(this.usersMap, teacherId)) {
              selection.application.teacher.email = this.usersMap[teacherId];
            }
          });
        }

        const zonesMap = new Map();
        const zonesArray = [];
        selections.forEach(selection => {
          const zone = selection.application?.student?.school?.zone;
          if (zone && !zonesMap.has(zone.id)) {
            zonesMap.set(zone.id, zone);
            zonesArray.push(zone);
          }
        })

        this.options.zones.items = zonesArray.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
        this.options.zones.loading = false
        if(!this.options.zones.items.map(zone => zone.id).includes(this.table.filters.zone.value)) {
          this.table.filters.zone.value = null
        }

        this.table.items = selections
        this.table.loading = false
      }
    },

		compareByInstrumentOrderAndPart(a, b) {
			return a.application.instrument.order - b.application.instrument.order
					|| a.part?.localeCompare(b.part, undefined, { numeric: true, sensitivity: 'base' })
					|| a.application.student.name.last.localeCompare(b.application.student.name.last)
					|| a.application.student.name.first.localeCompare(b.application.student.name.first)
		},

    async refresh() {
      this.table.loading = true
      await this.listSelections(this.table.filters.ensemble.value)
    },
    onFilter(item, filter) {
      /** If the search value is blank and every filter value is blank, return the row. **/
      if((!filter.filters) || (Object.values(filter.filters).every(f => _.isEmpty(f.value)))) {
        return true
      }

      /** Otherwise - Lets Search!  **/
      const keys = ['selectionEnsembleId']

      const fuse = new Fuse([item], {
        useExtendedSearch: true,
        threshold: 0.3,
        keys: keys
      })

      /** Build the Search Query  **/
      const query = { $and: [ ] }
      //For each filter that has a value, push it into the query
      if(filter.filters) {
        Object.values(filter.filters).filter(f => f.value).forEach(f => {
          query.$and.push({ [f.key]: `="${f.value}"` }) // use the = operator on the filter value to ensure it's an exact match and " to handle whitespace
        })
      }

      /** If the object we passed into the fuse constructor meets the search criteria, the row should be returned **/
      return fuse.search(query).length > 0
    },
    print() {
        this.$nextTick(() => {
            window.print()
        })
    }
  }
}
</script>

<style scoped>
  @media print {
    table > tbody > tr > td {
        font-size: 12px!important;
    }
    .print-title
    {
      display: block !important;
    }
    .badge {
        border-width: 0;
        padding: 0;
        background: transparent;
        color: inherit;
        font-weight: 400;
    }
}
</style>
