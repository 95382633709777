var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{ref:"layout",on:{"refresh":_vm.refresh},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(ref){return [_c('b-breadcrumb-item',{attrs:{"text":("Reports - " + (_vm.$store.state.settings.app.current.title))}}),_c('b-breadcrumb-item',{attrs:{"text":"Ensemble Roster","active":""}})]}},{key:"dropdown-options",fn:function(){return [_c('b-dropdown-item',{on:{"click":_vm.refresh}},[_c('feather-icon',{attrs:{"icon":"RotateCwIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Refresh")])],1)]},proxy:true},{key:"content",fn:function(ref){
var state = ref.state;
return [_c('span',{staticClass:"d-none d-print-block p-50 font-weight-bold"},[_vm._v(_vm._s(_vm.selectedEnsemble))]),_c('table-layout',{ref:"table-layout",attrs:{"loading":_vm.table.loading,"fields":_vm.table.fields,"items":_vm.table.items,"sorting":_vm.table.sorting,"filters":_vm.table.filters,"filters-options":{ visible: true, collapsed: false },"export-exclude-fields":[
                        'id',
                        'selectionEnsembleId',
                        'application.id',
                        'application.student.id',
                        'application.student.school.id',
                        'application.student.school.zone.id',
                        'application.instrument.id',
                        'application.teacher.id',
                        'application.teacher.username',
                        'iop', 'hasDetails'
                    ]},on:{"mounted":function($event){_vm.table = $event},"updated":function($event){_vm.table = $event}},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('overlay-loading',{attrs:{"items":[
            { state: _vm.table.loading, desc: 'Loading Selections', loaded: _vm.table.loaded},
            { state: state.loading, desc: 'Rendering Template'} ]}})]},proxy:true},{key:"filters",fn:function(){return [_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Ensemble","label-for":"ensemble-input"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ensemble ("),_c('span',{staticClass:"text-danger"},[_vm._v("required")]),_vm._v(") ")]},proxy:true}],null,true)},[_c('v-select',{staticClass:"w-100 d-print-none",attrs:{"id":"ensemble-input","options":_vm.options.ensembles.items,"loading":_vm.options.ensembles.loading,"reduce":function (ensemble) { return ensemble.id; },"label":"name","select-on-tab":true,"clearable":false,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},on:{"input":_vm.getSelections},model:{value:(_vm.table.filters.ensemble.value),callback:function ($$v) {_vm.$set(_vm.table.filters.ensemble, "value", $$v)},expression:"table.filters.ensemble.value"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Zone","label-for":"zone-input"}},[_c('v-select',{staticClass:"w-100 d-print-none",attrs:{"id":"zone-input","options":_vm.options.zones.items,"loading":_vm.options.zones.loading,"reduce":function (zone) { return zone.id; },"label":"name","disabled":!_vm.table.filters.ensemble.value,"select-on-tab":true,"clearable":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.zone.value),callback:function ($$v) {_vm.$set(_vm.table.filters.zone, "value", $$v)},expression:"table.filters.zone.value"}})],1)],1)],1)]},proxy:true},{key:"cell(application.student.address)",fn:function(ref){
          var data = ref.data;
return [(data.item.application.student.address)?_c('span',[_c('div',[_c('span',[_vm._v(_vm._s(data.item.application.student.address.line1))]),(data.item.application.student.address.line2)?_c('span',[_vm._v(", "+_vm._s(data.item.application.student.address.line2))]):_vm._e()]),_c('div',[_vm._v(" "+_vm._s(data.item.application.student.address.city)+", "+_vm._s(data.item.application.student.address.state)+" "+_vm._s(data.item.application.student.address.zip)+" ")])]):_vm._e()]}},{key:"cell(application.instrument.name)",fn:function(ref){
          var data = ref.data;
return [(_vm.hasValue(data, 'item.application.instrument'))?_c('span',[_vm._v(" "+_vm._s(data.item.application.instrument.name)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No Instrument")])]}},{key:"cell(application.instrument.order)",fn:function(ref){
          var data = ref.data;
return [(_vm.hasValue(data, 'item.application.instrument'))?_c('span',[_vm._v(" "+_vm._s(data.item.application.instrument.order)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No Instrument")])]}},{key:"cell(application.student.name.last)",fn:function(ref){
          var data = ref.data;
return [(_vm.hasValue(data, 'item.application.student.name'))?_c('span',[_vm._v(" "+_vm._s(data.item.application.student.name.last)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No Student")])]}},{key:"cell(application.student.name.first)",fn:function(ref){
          var data = ref.data;
return [(_vm.hasValue(data, 'item.application.student.name'))?_c('span',[_vm._v(" "+_vm._s(data.item.application.student.name.first)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No Student")])]}},{key:"cell(application.student.school.name.legal)",fn:function(ref){
          var data = ref.data;
return [(_vm.hasValue(data, 'item.application.student.school.name'))?_c('span',[_vm._v(" "+_vm._s(data.item.application.student.school.name.legal)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No School")])]}},{key:"cell(application.teacher.name)",fn:function(ref){
          var data = ref.data;
return [(_vm.hasValue(data, 'item.application.teacher.name'))?_c('span',[_vm._v(" "+_vm._s(data.item.application.teacher.name.first)+" "+_vm._s(data.item.application.teacher.name.last)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No Teacher")])]}},{key:"cell(application.student.school.zone.name)",fn:function(ref){
          var data = ref.data;
return [(_vm.hasValue(data, 'item.application.student.school.zone.name'))?_c('span',[_vm._v(" "+_vm._s(data.item.application.student.school.zone.name)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No Zone")])]}},{key:"cell(accepted)",fn:function(ref){
          var data = ref.data;
return [_c('b-badge',{attrs:{"variant":_vm.getAcceptedVariant(data.item.accepted)}},[_vm._v(" "+_vm._s(_vm.getAcceptedText(data.item.accepted))+" ")])]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }