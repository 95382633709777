// eslint-disable-next-line import/prefer-default-export
export const getUser = /* GraphQL */ `
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            username
            name {
                first
                last
            }
            ensembleIds
            instrumentIds
        }
    }
`;
export const listSelections = /* GraphQL */ `
    query ListSelections(
        $id: ID
        $filter: ModelSelectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                part
                selectionEnsembleId
                accepted
                ranking {
                    state
                }
                application {
                    id
                    instrument {
                        id
                        name
                        order
                    }
                    student {
                        name {
                            first
                            last
                        }
                        grade
                        address {
                            line1
                            line2
                            city
                            state
                            zip
                        }
                        phone {
                            number
                        }
                        email {
                            address
                        }                       
                        school {
                            name {
                                legal
                            }
                            district {
                                name {
                                    legal
                                }
                            }
                            zone {
                                id
                                name
                            }
                        }
                    }
                    teacher {
                        id
                        name {
                            first
                            last
                        }
                    }
                }
            }
            nextToken
        }
    }
`;

export const listEnsembles = /* GraphQL */ `
    query ListEnsembles(
        $id: ID
        $filter: ModelEnsembleFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listEnsembles(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
            }
            nextToken
        }
    }
`;
